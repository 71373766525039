








































































































































































































import { UmbrellaData } from "@/mixins/umbrella";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import Pricing from "@/components/order_form/Pricing.vue";
import Share from "@/components/share/Share.vue";
import ConfigFileService from "@/services/config_file_service";
import { ConfigCreator } from "@/mixins/configurator";
import { APIError, BadRequest } from "@/services/error_service";
import Download from "@/components/download/Download.vue";
import { CustomUmbrella } from "@/models/configurator/custom_umbrella";
import { FabricSwatch } from "@/models/products/fabric";
import { Auth } from "@/mixins/auth";
import { Roles } from "@/models/user/roles";
import EditWarning from "@/components/request_a_quote/EditWarning.vue";
import { ProductLineName } from "@/models/products/collection";
import Configurator from "@/components/designer/Configurator.vue";
import LeadTime from "@/components/order_form/LeadTime.vue";

Component.registerHooks(["beforeRouteEnter"]);
@Component({
  components: {
    Pricing,
    Share,
    Download,
    EditWarning,
    Configurator,
    LeadTime,
  },
})
export default class RequestQuote extends mixins(
  UmbrellaData,
  ConfigCreator,
  Auth
) {
  @Prop() props!: any;
  protected umbrella: CustomUmbrella | null = null;
  protected ProductLineName = ProductLineName;
  protected configFileService = new ConfigFileService();
  protected shareLink = "";
  protected showShareDialog = false;
  protected showEditDialog = false;
  protected Roles = Roles;
  protected qty = 1;

  @Watch("qty")
  protected onQtyChange(): void {
    const frame = (this.$refs.machform as HTMLIFrameElement).contentWindow;
    if (frame) {
      // https://stackoverflow.com/questions/42376464/uncaught-domexception-failed-to-execute-postmessage-on-window-an-object-co
      const qty = JSON.parse(JSON.stringify({ qty: this.qty }));
      const link = JSON.parse(JSON.stringify({ link: this.shareLink }));
      frame.postMessage(
        { ...qty, ...link },
        "https://www.frankfordumbrellas.com"
      );
    }
  }


  protected sendFrameMsg(): void {
    const frame = (this.$refs.machform as HTMLIFrameElement).contentWindow;
    if (frame) {
      // https://stackoverflow.com/questions/42376464/uncaught-domexception-failed-to-execute-postmessage-on-window-an-object-co
      const qty = JSON.parse(JSON.stringify({ qty: this.qty }));
      const link = JSON.parse(JSON.stringify({ link: this.shareLink }));
      frame.postMessage(
        { ...qty, ...link },
        "https://www.frankfordumbrellas.com"
      );
    }
  }

  async created() {
    if (!this.props.img1 && this.$route.query.quickship) {
      this.$router.replace("/resubmit?quickship=true");
    } else if (!this.props.img1) {
      this.$router.replace("/resubmit");
    }
    this.umbrella = this.getUmbrellaObject();
    if (!this.umbrella) {
      this.$router.replace("/designer");
    }
    await this.getShareLink();
  }

  protected minusQty(): void {
    if (this.qty > 1) {
      this.qty--;
    }
  }

  protected addQty(): void {
    this.qty++;
  }

  get image(): string {
    return this.props.img1;
  }

  protected closeShareModal(): void {
    this.showShareDialog = false;
  }

  public makeTitleCase(handle: string) {
    const words = handle.split(" ");
    return words
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(" ");
  }

  protected closeEditModal(): void {
    this.showEditDialog = false;
  }
  protected editConfig(): void {
    if (this.$route.query.quickship) {
      this.showEditDialog = true;
    } else {
      this.$router.replace("/designer/canopy");
    }
  }

  protected async share(): Promise<void> {
    const loader = this.$loading.show(
      {},
      { before: this.$createElement("h1", "Generating share link...") }
    );
    setTimeout(() => {
      loader.hide();
      this.showShareDialog = true;
    }, 1000);
  }

  protected async getShareLink(): Promise<void> {
    try {
      const body = await this.transformDataToConfigFile();
      const res = await this.configFileService.createShareFile(body, false);
      this.shareLink =
        process.env.VUE_APP_SHARE_LINK + "?design=" + res.share_id; //TODO
    } catch (err) {
      if (err instanceof BadRequest) {
        APIError.redirect("We are unable to generate a quote request for this umbrella. Please contact Frankford Umbrellas directly at info@frankfordumbrellas.com.");
      } else {
        APIError.popup(err.message, err.statusCode);
      }
    }
  }

  protected getFabricNames(fabrics: FabricSwatch[]): string {
    const names = fabrics.map((swatch) => swatch.name);
    return names.join(", ");
  }
  protected formatTitle(title: string, part: string): string {
    let result = "";
    if (title.indexOf(" ")) {
      if (part == "a") {
        result = title.substr(0, title.indexOf(" "));
      } else {
        result = title.substr(title.indexOf(" ") + 1);
      }
    } else {
      return title;
    }

    return result;
  }
}
